@import "Mixins";

.app {
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: auto;
  height: 100vh;

  & .app__header {
    $ButtonWidth: 6rem;
    
    display: grid;
    grid-template-columns: 1fr $ButtonWidth;
    border-bottom: 1px solid #162a46;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

    @include tablet-down {
      padding-top: unset;
      padding-bottom: unset;
    }
    
    & .app__link {
      display: flex;
      align-items: center;
      gap: 1rem;
      padding: 0.5rem;
      text-align: center;
      text-decoration: none;
      color: #fff;
      
      h1 {
        margin-bottom: 0;
        font-size: 2.25rem;

        @include tablet-down {
          display: none;
        }
      };

      @include tablet-down {
        justify-content: center;
        padding-left: $ButtonWidth;
      }

      & .app__logo {
        max-width: 2.5rem;
        max-height: 2.5rem;
      }
    }
    
    & > button {
      height: 100%;
      min-width: 5rem;
      display: none;
      
      @include tablet-down {
        display: unset;
      }
    }
  }

  & .app__main {
    overflow-y: auto;
    overflow-x: hidden;
  }

  & .app__footer {
    overflow: hidden;
    display: flex;
    padding: 1rem;
    justify-content: center;
    gap: 2rem;
  }
}