.alert {
  display: grid;
  grid-template-rows: auto auto auto;
  background-color: #162a46;
  border: 1px solid lighten(#162a46, 5%);
  border-radius: 10px;
  align-items: center;
  margin-top: 1rem;
  overflow: hidden;

 & > button {
    border: 0;
    border-radius: 0;
  }
  
  & > p {
    margin: 0;
    padding: 0.5rem;
  }

  & > * {
    border-bottom: 1px solid lighten(#162a46, 5%);
    overflow: hidden;
    word-wrap: break-word;
    
    &:last-child {
      border-bottom: 0;
    }
  }
}