.toast {
  word-wrap: break-word;
  border: 3px solid red;
  border-radius: 3px;
  padding: 1rem;
  background-color: #162a46;

  &.toast--successful {
    border-color: green;
  }
}