.field {
  display: flex;
  flex-direction: column;
  
  & > input {
    $FontAscenderMarginCorrection: 0.50rem;

    padding: $FontAscenderMarginCorrection 0.25rem 0.25rem;
    min-height: 2rem;
    color: #fff;
    border-radius: 5px;
    background-color: #162a46;
    border: 1px solid lighten(#162a46, 5%);
  }
}