$TabletBreakpoint: 768px;
$MobileBreakpoint: 480px;

@mixin tablet-down {
  @media only screen and (max-width: $TabletBreakpoint) {
    @content;
  }
}

@mixin mobile-down {
  @media only screen and (max-width: $MobileBreakpoint) {
    @content;
  }
}