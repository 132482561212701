.user {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  text-align: center;

  margin-bottom: 1rem;

  & > .user__container {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    padding: 1rem;
    background-color: #162a46;
    border: 1px solid lighten(#162a46, 5%);

    p {
      margin: 0.5rem 0 0;
    }

    img {
      max-width: 2.5rem;
      max-height: 2.5rem;
      align-self: center;
    }
  }
}