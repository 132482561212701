.loading-spinner {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;

  & svg {
    display: block;
  }
}