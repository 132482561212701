// https://onlinefontconverter.com/

@mixin font($font-family, $font-file) {
  @font-face {
    font-family: $font-family;
    src: url($font-file + '.eot');
    src: url($font-file + '.eot?#iefix') format('embedded-opentype'),
    url($font-file + '.woff') format('woff'),
    url($font-file + '.ttf') format('truetype'),
    url($font-file + '.svg#' + $font-family) format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
}

@include font('NotoSans', '../assets/fonts/NotoSans-Regular');
@include font('FoundersGrotesk', '../assets/fonts/FoundersGrotesk-Light');
@include font('MaisonNeue-Bold', '../assets/fonts/MaisonNeue-Bold');