.form {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  & > .form__field {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  & > button {
    margin-top: 1rem;
  }
}