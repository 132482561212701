@import 'Mixins';

.tiles {
  display: flex;
  flex-direction: row;

  @include tablet-down {
    flex-direction: column;
  }
  
  & > .tile__article {
    flex-grow: 1;
    flex-basis: 0;
    background-color: #162a46;
    border: 1px solid lighten(#162a46, 5%);
    border-right: 0;
    padding: 1rem;

    &:first-child {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }
    
    &:last-child {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      border-right: 1px solid lighten(#162a46, 5%);
    }

    @include tablet-down {
      border: 1px solid lighten(#162a46, 5%);
      border-bottom: 0;
      
      &:first-child {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        border-bottom-left-radius: 0;
      }

      &:last-child {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        border-top-right-radius: 0;
        border-bottom: 1px solid lighten(#162a46, 5%);
      }
    }

    & > .tile__icon {
      max-width: 5rem;
      max-height: 5rem;
    }
  }
}