.home {
  & > .home__unauthenticated {
    & > .home__information {
      text-align: center;
      margin-bottom: 2rem;
    }

    & > .home__log-in-prompt {
      display: flex;
      justify-content: center;
      flex-direction: column;
      gap: 1rem;
      text-align: center;
    }
  }
  
  & > .home__authenticated {
    & > .home__pages {
      margin-bottom: 1rem;

      display: flex;
      flex-wrap: wrap;
      gap: 2rem;
      align-items: center;
      justify-content: center;
    }
  }
}