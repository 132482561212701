@import "styles/Fonts";
@import "styles/Mixins";

body {
  margin: auto;
  font-family: 'FoundersGrotesk', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #001534;
  color: #fff;
  font-size: 1.313rem;
  max-width: 1200px;

  @include mobile-down {
    font-size: 18px;
  }
}

a {
  color: #b8d5ee;

  &:hover {
    color: #feffff;
  }

  &:active {
    color: #FFFFFF;
  }
}

h1, h2 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-family: 'MaisonNeue-Bold', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
}

p {
  margin-top: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}