@import 'Mixins';

.button {
  $ButtonBackgroundBrush: #0084FF;
  $FontAscenderMarginCorrection: 0.75rem;
  
  display: flex;
  flex-direction: row;
  border: 1px solid black;
  border-radius: 3px;
  background-color: $ButtonBackgroundBrush;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  cursor: pointer;
  color: #fff;
  line-height: 1;
  padding: $FontAscenderMarginCorrection 0.5rem 0.5rem;
  min-width: 13rem;
  align-self: center;
  
  @include mobile-down {
    align-self: unset;
  }
  
  &.button--disabled {
    background-color: darken($ButtonBackgroundBrush, 10%);
    cursor: not-allowed;
  }

  &:active,
  &:hover {
    background-color: darken($ButtonBackgroundBrush, 15%);
  }

  & > .button__text {
    font-weight: bold;
  }
}